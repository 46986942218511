// Initial State
const initialState = {};

const CustomerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'CUSTOMER.SET_CUSTOMER':
      return {
        ...state,
        customer: action.customer,
      };
    case 'CUSTOMER.INIT':
      return {};
    default:
      return state;
  }
};

// Export Reducer
export default CustomerReducer;
